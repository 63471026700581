<template>
<div class="diaPage">
  <FloatMc/>
  <div style="margin: 10px auto;text-align: center;">
  <img src="../assets/img/find/znft.png" width="100px" />
  </div>
    <div class="imgGrid">
      <div>
        <img src="../assets/img/showlogin/sl_0.png" />
      </div>
      <div>
        <img src="../assets/img/showlogin/sl_1.png" />
      </div>
      <div>
        <img src="../assets/img/showlogin/sl_2.png" />
      </div>
    </div>
  <div class="imgGrid">
      <div>
        <img src="../assets/img/showlogin/sl_5.png" />
      </div>
      <div>
        <img src="../assets/img/showlogin/sl_3.png" height="135px"/>
      </div>
      <div>
        <img src="../assets/img/showlogin/sl_4.png"/>
      </div>
    </div>

    <div style="text-align: center;margin-top: -80px">
      <div style="margin: 15px auto;margin-bottom: 5px">
        <img src="../assets/img/showlogin/sh_user.png" width="100px" style=""/>
      </div>
      <div style="margin:0 auto;color: #FFFFFF;font-size: 18px;width: 80%;text-align: center">
        {{$t('login.loginMsg')}}
      </div>
      <div style="margin-top: 20px">
        <van-button @click="$router.push('/login')">{{$t('login.login')}}</van-button>
      </div>
      <div style="margin-top: 15px">
        <van-button @click="$router.push('/reg')" class="van-button1">{{$t('login.Register')}}</van-button>
      </div>
      <div style="margin: 15px auto;text-align: center;font-size: 15px;" @click="$router.push('/')" >
        {{$t('index.backhome')}}
      </div>
  </div>
</div>
</template>

<script>
export  default {
  data () {
    return  {
      showMsg: false,
      showLang:false,
      show_info:false,
      titles:{},
    }
  },
  watch: {
  },
  mounted () {
    console.log("aaaaa",this.pagetype)
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.diaPage{
  background-color: #000000;
  padding-top: 0px;
  position: fixed;
}

.div {
  background-color: transparent;
}

.imgGrid{
  display: flex;
  img {
    width: 100%;
  }
}
.div {
  padding: 0px;
  margin: 0px;
  height: 120px;
}
</style>
