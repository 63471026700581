<template>
<div class="page">
  <div class="top_div">
    <div class="mid_div flex_sb" v-show="!logined" style="width: 80%;margin: 0 auto">
      <div class="h14"></div>
      <div style="font-size: 14px" class="btn1" @click="$router.push('/login')">{{$t('login.login')}}</div>
      <div style="font-size: 14px;width: 120px" class="btn1" @click="$router.push('/reg')">{{$t('login.Register_now')}}</div>
    </div>
    <div class="mid_div flex_sb" v-show="logined">
      <div class="btn1" @click="toPage('merchant')">{{$t('my.Merchant')}}</div>
      <div class="avatar">
        <img :src="UserInfo.header" alt="">
      </div>
      <div class="btn1" @click="toPage('goodsList')">{{$t('my.Fragments')}}</div>
    </div>
    <div class="top_bottom" v-show="logined">
      <div class="info_div flex_sb">
        <div class="info_item">
          <div class="info_num" @click="toPage('myCollect')">{{UserInfo.collect_number}}</div>
          <div class="info_text" @click="toPage('myCollect')">{{$t('my.myCollect')}}</div>
        </div>
        <div class="info_item">
          <div class="info_num" @click="toPage('mynft')">{{UserInfo.nft_number}}</div>
          <div class="info_text">{{$t('my.myNFT')}}</div>
        </div>
      </div>

      <div class="username" v-show="logined">
        <span>{{UserInfo.username}} <img :src="vips[UserInfo.vip_level]" alt=""></span>
      </div>
      <div class="line" style="margin-top: 20px;margin-bottom: 5px"></div>

      <div class="info_div flex_sb" v-show="logined">
        <div class="info_item" @click="toPage('osell')">
          <div class="info_num">{{UserInfo.debris_number}}</div>
          <div class="info_text">{{$t('onekey.royalties')}}</div>

          <div class="btn_top">{{$t('my.Exchange')}}</div>
        </div>
        <div class="info_item" @click="toPage('OTCtrading')">
          <div class="info_num">{{parseInt(UserInfo.balance)}}</div>
          <div class="info_text">{{$t('my.OTCbalance')}}</div>

          <div class="btn_top">{{$t('my.OTCwallet')}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="list">
    <div class="list_item flex_sb flex_ac" v-for="i,k in menuList" :key="k" @click="toDetail(i)">
      <div class="flex_ac">
        <div class="item_icon">
          <img :src="i.icon" alt="">
        </div>
        <div>{{i.title}}</div>
      </div>
      <img src="~@/assets/img/right.png" alt="">
    </div>
    <div class="list_item flex_sb flex_ac" v-if="logined" @click="gotoService">
      <div class="flex_ac">
        <div class="item_icon">
          <img src="@/assets/img/my/menuIcon7.png" alt="">
        </div>
        <div>{{$t('my.Contact_Customer_Service')}}</div>
      </div>
      <img src="~@/assets/img/right.png" alt="">
    </div>
  </div>

  <div class="btn" @click="gotoService" v-if="!logined" style="display: flex;Justify-content: center;margin: 0 auto">
    <img src="~@/assets/img/icon_service2.png" width="22" height="22px" style="margin-right: 10px;margin-top: 9px">
    {{$t('my.Contact_Customer_Service')}}
  </div>
  <div class="btn" @click="logout" v-show="logined">{{$t('my.sign_out')}}</div>
  <div class="open">
    <van-dialog v-model="isShow" title="" :showConfirmButton='false' :showCancelButton="false">
      <div class="pop_bg">
        <div class="close" @click="isShow=false">
          <img src="~@/assets/img/index/close.png" alt="">
        </div>
        <div class="aboutNft" style="text-align: center">{{msg.preson_msg_title}}</div>
        <div class="content" style="text-align: center" v-html="msg.preson_msg_content"></div>
      </div>
    </van-dialog>
  </div>
</div>
</template>
<script>

import icon0 from '@/assets/img/my/menuIcon0.png'
import icon1 from '@/assets/img/my/menuIcon1.png'
import icon2 from '@/assets/img/my/menuIcon2.png'
import icon3 from '@/assets/img/my/menuIcon3.png'
import icon4 from '@/assets/img/my/menuIcon4.png'
import icon5 from '@/assets/img/my/menuIcon5.png'
import icon6 from '@/assets/img/my/menuIcon6.png'
import icon7 from '@/assets/img/my/menuIcon7.png'
import icon8 from '@/assets/img/my/menuIcon8.png'
import vs1 from '@/assets/img/my/vs1.png'
import vs2 from '@/assets/img/my/vs2.png'
import vs3 from '@/assets/img/my/vs3.png'
import vs4 from '@/assets/img/my/vs4.png'
import vs5 from '@/assets/img/my/vs5.png'
import config from "@/config";

export default{
  data(){
    return{
      vips:[0,vs1,vs2,vs3,vs4,vs5],
      menuList:[
        {title:this.$t('my.Edit_information'),icon:icon0,url:'editInfo'},
        {title:this.$t('my.Transfer_to_NFT'),icon:icon1,url:'NFTimport'},
        {title:this.$t('my.Staking_TNFT'),icon:icon2,url:'pledgeList'},
        {title:this.$t('my.Account_binding'),icon:icon3,url:'account'},
        {title:this.$t('my.Team_list'),icon:icon4,url:'teamList'},
        {title:this.$t('my.Invite_members'),icon:icon5,url:'share'},
        {title:this.$t('my.Royalty_Details'),icon:icon6,url:'royaltyDetails'},
      ],
      timer:null,
      isShow:false,
      msg:{
        preson_msg_title:'',
        preson_msg_content:'',
        withdraw_msg_title:'',
        withdraw_msg_content:''
      },
      logined:false
    }
  },
  created(){
    console.log("xxx:",this.UserInfo);
    if (this.UserInfo){
      this.logined = true;
      this.$Model.GetUserInfo();
    }else{

    }
    // this.timer = setInterval(()=>{
    //   this.$Model.GetUserInfo();
    //   console.log("xxxx")
    // },3000)
  },
  destroyed() {
    //clearInterval(this.timer)
  },
  methods:{
    toDetail(i){
      if (i.url=="showlang"){
        this.showLang = true;
      }else if (i.title==this.$t('my.Contact_Customer_Service')){
        this.gotoService()
      }else if (i.url.length>0){
        this.$router.push(i.url)
      }
    },
    gotoService(){
      var init = this.InitData;
      var clang = "ko";
      var clangs = {'zh':'cn','kr':'ko','en':'en','jp':'ja','vn':'vi'};
      var lang = localStorage['Language'] || config.Language;
      if (clangs[lang]){
        clang = clangs[lang];
      }
      if (init['setting']&&init['setting']['service_url']&&init['setting']['service_url'].length>0){
        window.location.href = init['setting']['service_url']+"?language="+clang
      }
    },
    getMsg(){
      let param = {}
      //debugger
      this.$http.post('/Notice/getMsg',param).then(res=>{
        if (!res||!res.data){
          return;
        }
        res = res.data
        //this.$toast(res.code_dec)
        if (res.code!=1){
          return
        }else{
          this.msg = res.data
          // res.data.preson_msg_title = "eeeeeeeeeeeee";
          // res.data.preson_msg_content = "bbbbbbbbb";
          if(res.data.preson_msg_title){
            this.isShow = true
          }
        }
      })
    },
    toPage(url){
      if (url.length>0){
        this.$router.push(url)
      }
    },
    logout(){
      this.$Model.Logout()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/my/my.scss";
.page {
  background: #020202;
  margin-top: 5px;
}
</style>
