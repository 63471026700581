<template>
  <div>
    <FloatMc/>
    <van-nav-bar fixed :border="false" style="height: 62px">
      <div slot="left" style="display: flex">
        <img src="../assets/img/detail.png" width="25px" style="margin-top: 5px;margin-right: 10px"
             @click="isShow=!isShow"/>
      </div>
      <div slot="title" style="">
        <img src="../assets/img/find/znft.png" width="95px"  style="margin-top: 10px"/>
      </div>
      <div style="" slot="right" @click="toPage('search')">
        <img src="../assets/img/my/search2.png" width="28px" style="margin-top: 8px">
      </div>
    </van-nav-bar>
    <van-nav-bar v-if="title||hasLeft"
                 :left-arrow="hasLeft" :title="title"
                 @click-left="$router.go(-1)"
                 :border="false" style="margin-top: 10px;z-index: 0">
      <div slot="right" style="display: flex">
        <img v-if="pagetype=='trading'" width="26" height="21" src="~@/assets/img/my/auth.png" alt="" style="margin-right:10px;" @click="auth">
        <img v-if="pagetype=='trading'" width="26" height="21" src="~@/assets/img/my/time.png" alt="" @click="$router.push('OTCrecords')">
        <img v-if="pagetype=='nftimport'" width="26" height="21" src="~@/assets/img/my/time.png" @click="$router.push('NFTimportRecords')"/>
        <img v-if="pagetype=='pledge'" width="26" height="21" src="~@/assets/img/my/time.png"
             @click="$router.push('pledgeRecord')"/>
        <img v-if="pagetype=='create'" width="26" height="21" src="~@/assets/img/my/time.png"
             @click="$router.push('createLog')"/>
      </div>
    </van-nav-bar>
    <van-popup v-model="isShow" position="left" style="background: #020202; height:100%;width: 85%"
                :showConfirmButton='false' :showCancelButton="false">
      <left-panel></left-panel>
    </van-popup>
  </div>
</template>
<script>

export  default {
  // 使用时请使用 :url.sync=""传值
  props: [
    "pagetype","title","hasLeft","hasTitle","noBalance"
  ],
  data () {
    return  {
      showMsg: false,
      showLang:false,
      show_info:false,
      isShow:false,
      titles:{},
    }
  },
  computed: {
    uname(){
      var username = this.UserInfo.username;
      var nickname = this.UserInfo.nickname;
      var uuname = username;
      if (nickname&&nickname.length>0&&nickname!=username){
        uuname = nickname;
      }else{
        var name = username;
        if(isNaN(Number(name,10))){
          uuname = name.substring(0,3)+"*****"+name.substring(name.length-10,name.length);
        }else{
          uuname = name.substring(0,3)+"***"+name.substring(name.length-3,name.length);
        }
      }

      return uuname;
    }
  },
  watch: {
  },
  mounted () {
    console.log("aaaaa",this.pagetype)
  },
  methods: {
    onClickHall(){
      this.$router.push("/user/userTaskRecord");
    },
    onClickUser(index){
      if (index==1){
        this.$router.push("/user/msglist");
      }else{
        this.$router.push("/user/info");
      }
    },
    toPage(url){
      if(!url){
        this.$toast(this.$t('my.noSart'))
        return
      }
      this.$router.push(url)
    },
    auth(){
      this.$router.push('auth')
    },
    gotoService(){
      var init = this.InitData;
      if (init['setting']&&init['setting']['service_url']&&init['setting']['service_url'].length>0){
        window.location.href = init['setting']['service_url']
      }
    },
  }
}
</script>


<style scoped>
.Header{

}
.MyHeader {
  font-size: 19px;
  border-radius: 50%;
  background: #CCCCCC;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin-left: 20px;
  margin-bottom: 5px;
  text-align: center;
}
.MyHeader img {
  width: 100%;
}

.search_text {
  margin-left: 10px;
  color: #9c9c9c;
}

.van-cell {
  height: 20px;
}
</style>

