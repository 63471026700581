<template>
  <div>

<!--    <div class="floatMc">-->
<!--      <img src="../assets/img/find/icon_mc1.png" width="55px"/>-->
<!--    </div>-->
<!--    <div class="floatMc2">-->
<!--      <img src="../assets/img/find/icon_mc2.png" width="55px"/>-->
<!--    </div>-->
  </div>
</template>
<script>

export  default {
  // 使用时请使用 :url.sync=""传值
  props: [],
  data () {
    return  {
      container:null,
      snowflakes:[],
      maxSnowflakes:1000
    }
  },
  mounted() {
    // this.container = this.$refs.snow;
    // this.loadSnow()
  },
  methods: {
    loadSnow() {
      var that = this;

      console.clear();

      let isRunning = true;
      const generatesnowFlake = (timeout = 0, init = false) => {
        const duration = 3000 + Math.random() * 7000,
            flake = document.createElement("div"),
            id = crypto.randomUUID(),
            delay = init ? Math.random() * duration : 0;
        that.snowflakes.push(id);
        setTimeout(() => {
          var that2 = that;
          flake.setAttribute("id", id);
          flake.setAttribute(
              "style",
              `
    			animation-delay: -${delay}ms;
    			--fallDuration: ${duration}ms;
    			--fallSlideStrength: ${Math.random()};

    			--size: ${Math.random() * 0.7 + 0.3};
    			--position: ${Math.random() * 120}%;
    			`
          );
          that.container.appendChild(flake);
          setTimeout(() => {
            const index = that2.snowflakes.findIndex((e) => e === id);
            that2.snowflakes.splice(index, index);
            // console.log('aa',that2.container);
            that2.container.removeChild(flake);

          }, duration - delay);
        }, timeout);
      };

      that.container.setAttribute("style", `--cWidth: ${that.container.clientWidth}px`);
      addEventListener("resize", () =>
          that.container.setAttribute("style", `--cWidth: ${that.container.clientWidth}px`)
      );

      const loop = async () => {
            while (1) {
              await new Promise(async (resolve) => {
                if (isRunning && that.snowflakes.length < that.maxSnowflakes && !document.hidden) {
                  requestAnimationFrame(() => {
                    generatesnowFlake(Math.random() * 50);
                    resolve();
                  });
                } else {
                  setTimeout(resolve, 50);
                }
              });
            }
          },
          init = () => {
            for (let i = 0; i < (that.maxSnowflakes - that.snowflakes.length) / 2; i++) {
              generatesnowFlake(Math.random() * 50, true);
            }
          };

      init();
      loop();

      document.onvisibilitychange = (e) => {
        isRunning = !document.hidden;
        if (isRunning) init();
      };
    }
  }

}
</script>


<style scoped>
.floatMc{
  position:fixed;
  top:300px;
  left: 0px;
  z-index: 100;
}
.floatMc2{
  position:fixed;
  top:290px;
  right: 0px;
  z-index: 100;
}

body #snow > div {
  position: absolute;
  top: 0;
  left: calc(var(--position) - 20%);
  width: calc(var(--size) * 15px);
  aspect-ratio: 1;
  background: radial-gradient(white, transparent 66%);
  -webkit-animation: var(--fallDuration) snowFall linear forwards;
  animation: var(--fallDuration) snowFall linear forwards;
}

@-webkit-keyframes snowFall {
  to {
    top: 100%;
    transform: translateX(calc(var(--cWidth) * var(--fallSlideStrength) / 8));
  }
}

@keyframes snowFall {
  to {
    top: 100%;
    transform: translateX(calc(var(--cWidth) * var(--fallSlideStrength) / 8));
  }
}
</style>

